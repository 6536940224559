<template>
    <div>
        <label :for="id" class="block text-sm font-medium text-gray-700">
            <slot></slot>
        </label>
        <select v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                :id="id"
                class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option
                v-for="(v,i) in values"
                :key="i"
                :value="v">
                {{ text[i] }}
            </option>
        </select>
    </div>
</template>

<script>
import uniqueId from "lodash/uniqueId";

export default {
    name: "SelectMenu",

    props: {
        value: {},
        values: {
            type: Array,
        },
        text: {
            type: Array,
        }
    },

    data: () => ({
        id: uniqueId(),
    }),

}
</script>
