<template>
    <card-full-invisible>
        <template slot="header">
            Schreibtisch buchen
        </template>

        <div class="mb-8 p-4">
            <div class="mb-4">
                <input-text type="date" v-model="from" :has-error="!isValidFrom">
                    Von
                </input-text>
            </div>

            <error :error="error" type="from"/>

            <!-- only show this error, if there is no from error -->
            <error :error="error" type="to" v-show="error && error.errors && !error.errors['from']"/>

            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <select-menu :values="[1, 2, 3, 4, 5]" :text="['1 Tag','2 Tage','3 Tage','4 Tage','5 Tage']"
                             v-model="duration">
                    Dauer
                </select-menu>
            </div>

            <div v-if="room"
                 class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <span class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Raum</span>
                <div>
                    <a @click="room = null; desk = null"
                       class="cursor-pointer inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-indigo-100 text-indigo-800"
                    >
                        {{ room.name }}
                        <button type="button"
                                class="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700"
                                aria-label="">
                            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
                            </svg>
                        </button>
                    </a>
                </div>
            </div>

            <div v-if="desk"
                 class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <span class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">Schreibtisch</span>
                <div>
                    <span @click="desk = null"
                          class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-indigo-100 text-indigo-800"
                    >
                        {{ desk.name }}
                        <button type="button"
                                class="flex-shrink-0 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700"
                                aria-label="">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
                        </svg>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div v-show="!desk && !room">
            <div class="my-4">

                <h2 class="p-4 text-gray-500 text-xs font-medium uppercase tracking-wide my-3">
                    Freie Schreibtische für
                    <span v-show="duration > 1">{{ from | date }} - {{ to | date }}</span>
                    <span v-show="parseInt(duration) === 1">{{ from | date }}</span>
                </h2>

                <div v-show="loading" class="p-4 flex justify-center">
                    <i class="fas fa-spinner fa-spin fa-fw"/>
                </div>

                <div v-show="!loading">

                    <div v-show="suggestions.length === 0">
                        Keine buchbaren Schreibtische gefunden.

                        <span class="font-bold" v-if="error && error.message && error.message.substr(0,4 ) !== 'The '">
                            {{ error.message }}
                        </span>
                    </div>

                    <tailwind-list v-show="roomSuggestions.length > 0">
                        <tailwind-list-item v-for="(sug) in roomSuggestions"
                                            :key="sug.id"
                                            @click="room = sug">
                            <i class="fas fa-users"/> {{ sug.name }}
                            <template slot="secondary">
                                Noch {{ filterByRoom(sug).length }}/{{ sug.desks_count }} Schreibtische verfügbar<br>
                            </template>
                        </tailwind-list-item>
                        <tailwind-list-item v-for="sug in groupByRoom(unavailable)" :key="sug.id"
                                            class="bg-gray-100 text-red-200">
                            <i class="fas fa-users"/> {{ sug.name }}
                            <template slot="secondary">
                                Raum ist nicht buchbar.
                            </template>
                        </tailwind-list-item>
                    </tailwind-list>

                    <tailwind-list v-show="deskSuggestions.length > 0">
                        <tailwind-list-item v-for="sug in deskSuggestions"
                                            :key="sug.id"
                                            @click="desk = sug">
                            <i class="fas fa-desktop mr-2 "/> {{ sug.name }}
                            <template slot="secondary">
                                Typ: {{ sug.owner === null ? sug.type : 'Mitarbeiter Schreibtisch' }}
                            </template>
                        </tailwind-list-item>
                    </tailwind-list>
                    <tailwind-table :header="['Schreibtisch', 'Typ', '']" v-show="deskSuggestions.length > 0">
                        <tr v-for="(sug, i) in deskSuggestions"
                            :key="sug.id"
                            :class="{'bg-gray-50': i%2}"
                            @click="desk = sug"
                            class="cursor-pointer">
                            <td class="px-6 py-2 w-1/2"><i class="fas fa-desktop mr-2 "/> {{ sug.name }}</td>
                            <td class="px-6 py-2 text-right">

                            </td>
                            <td class="px-2 py-2 text-right">
                                <button-link>Auswählen</button-link>
                            </td>
                        </tr>
                    </tailwind-table>

                    <div v-show="deskSuggestions.length > 0" class="text-gray-500 p-4">
                        Schreibtisch auswählen
                    </div>
                </div>

            </div>

        </div>

        <div v-if="room && !desk">

            <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide my-3">Raum {{ room.name }}</h2>

            <tailwind-list>
                <tailwind-list-item v-for="sug in suggestionsByRoom"
                                    :key="sug.id"
                                    @click="desk = sug">
                    <i class="fas fa-desktop mr-2 "/> {{ sug.name }}
                    <template slot="secondary">
                        Typ: {{ sug.owner === null ? sug.type : 'Mitarbeiter Schreibtisch' }}
                    </template>
                </tailwind-list-item>
            </tailwind-list>

            <div v-show="room.map_link">
                <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide my-3">Lageplan</h2>

                <img class="w-full my-4" :src="room.map_link" :alt="room.name">
            </div>
        </div>

        <div v-if="desk">

            <div class="mb-8">
                Schreibtisch <span class="font-bold">
                            <i class="fas fa-desktop"></i> {{ desk.name }}
                        </span>
                für {{ from | date }}
                <span v-show="from !== to"> bis {{ to | date }}</span>
                reservieren?
            </div>

            <div class="flex justify-end">
                <button-primary v-show="!saving" @click="create()">
                    Schreibtisch buchen
                </button-primary>

                <button-primary v-show="saving">
                    <i class="fas fa-spinner fa-spin fa-fw"/> Speichern
                </button-primary>
            </div>
        </div>
    </card-full-invisible>
</template>

<script>
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import ButtonPrimary from "../components/UI/ButtonPrimary";
import TailwindTable from "../components/UI/TailwindTable";
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import addDays from 'date-fns/addDays';
import ButtonLink from "../components/UI/ButtonLink";
import SelectMenu from "../components/UI/SelectMenu";
import CardFullInvisible from "../components/UI/CardFullInvisible";
import TailwindList from "../components/UI/TailwindList";
import TailwindListItem from "../components/UI/TailwindListItem";
import InputText from "../components/UI/InputText";
import Error from "@/components/Error";

export default {
    name: "Reservations",
    components: {
        Error,
        InputText,
        TailwindListItem,
        TailwindList,
        CardFullInvisible, SelectMenu, ButtonLink, TailwindTable, ButtonPrimary
    },

    data: () => ({
        loading: false,
        saving: false,
        duration: 1,
        from: new Date().toISOString().slice(0, 10),
        suggestions: [],
        unavailable: [],
        desk: null,
        room: null,
        error: null,
    }),

    filters: {
        date(datetime) {
            const date = new Date(datetime);

            const day = ('0' + date.getDate()).slice(-2);
            const month = ('0' + (date.getMonth() + 1)).slice(-2);

            return `${day}.${month}.`;
        },
    },

    watch: {
        from() {
            if (!this.isValidFrom) {
                this.suggestions = [];
                return;
            }
            this.ask();
        },
        to() {
            this.ask();
        }
    },

    mounted() {
        this.open();
    },

    computed: {
        isAppointment() {
            // eslint-disable-next-line no-undef
            return Office.context.mailbox.item.itemType === 'appointment';
        },
        to() {
            // what happens if this.from is invalid?
            if (!this.isValidFrom) {
                return new Date();
            }
            return addDays(new Date(this.from), this.duration - 1)
                .toISOString()
                .slice(0, 10);
        },
        isValidFrom() {
            const time = new Date(this.from);

            if (!isValid(time)) {
                return false;
            }

            if (isSameDay(time, new Date())) {
                return true;
            }

            return !isBefore(time, new Date());
        },
        suggestionsByRoom() {
            if (!this.room) {
                return [];
            }

            return orderBy(
                this.suggestions.filter(desk => desk.room && desk.room.id === this.room.id),
                ['name'],
                ['asc']
            );
        },
        roomSuggestions() {
            let rooms = this.suggestions.filter(desk => desk.room).map(function (desk) {
                return desk.room;
            });
            return orderBy(
                Object.values(groupBy(rooms, 'id')).map(function (rooms) {
                    let item = rooms[0];
                    item.count = rooms.length;
                    return item;
                }),
                ['name'],
                ['asc']
            );
        },
        deskSuggestions() {
            return orderBy(
                this.suggestions.filter(desk => !desk.room),
                ['name'],
                ['asc']
            );
        },
    },

    methods: {
        toOutlook() {
            if (this.isAppointment) {
                return this.createAppointment();
            }
            return this.createMessage();
        },
        createAppointment() {
            // eslint-disable-next-line no-undef
            if (Office.context.mailbox.displayNewAppointmentForm === undefined) {
                // try to access the appointment form directly!
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.start.setAsync(new Date(this.from + ' 00:00:00'));
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.end.setAsync(new Date(this.to + ' 23:55:00'));
                // eslint-disable-next-line no-undef
                Office.context.mailbox.item.location.setAsync(this.desk.name);
                // part of preview: https://docs.microsoft.com/en-us/javascript/api/outlook/office.isalldayevent?view=outlook-js-preview
                // eslint-disable-next-line no-undef
                //Office.context.mailbox.item.isAllDayEvent.setAsync(true);
            }
        },
        createMessage() {
            let day = this.$options.filters.date(this.from);
            if (this.from !== this.to) {
                day += ' - ' + this.$options.filters.date(this.to);
            }
            // eslint-disable-next-line no-undef
            Office.context.mailbox.item.body.prependAsync(
                `Schreibtisch: ${this.desk.name}, Datum: ${day} `
            );
        },
        hasError(type) {
            if (this.error === null || this.error.errors === undefined) {
                return false;
            }

            return this.error.errors[type] !== undefined;
        },
        filterByRoom(room) {
            return this.suggestions.filter(desk => desk.room && desk.room.id === room.id);
        },
        groupByRoom(items) {
            let rooms = items.filter(desk => desk.room).map(function (desk) {
                return desk.room;
            });
            return Object.values(groupBy(rooms, 'id')).map(function (rooms) {
                let item = rooms[0];
                item.count = rooms.length;
                return item;
            });
        },
        open(value = null) {
            this.room = null;
            this.desk = null;
            this.from = this.$route.query.from ? this.$route.query.from : new Date().toISOString().slice(0, 10);
            if (value) {
                this.from = value;
            }

            this.ask();
        },
        create() {
            this.saving = true;
            this.$http.post('/reservations', {
                type: this.type,
                from: this.from,
                to: this.to,
                desk_id: this.desk.id,
            })
                .then(() => {
                    this.toOutlook();
                    this.saving = false;
                    // component reload
                    this.$emit('reload');
                    // global reload
                    this.$store.dispatch('reload');
                    this.$router.replace('/');
                })
                .catch(response => {
                    this.$store.dispatch('handleErrorResponse', response);
                });
        },
        ask() {
            this.loading = true;
            this.suggestions = [];
            this.unavailable = [];
            this.desk = null;
            this.room = null;
            this.error = null;
            this.$http.get('/reservations', {
                params: {
                    from: this.from,
                    to: this.to,
                },
            }).then(response => {
                if (response.data.from !== this.from && response.data.to !== this.to) {
                    return;
                }
                this.suggestions = response.data.data;
                this.unavailable = response.data.unavailable;
                this.desk = null;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                // no global error handling here!
                this.error = error.response.data;
            });
        }
    }
}
</script>

<style scoped>

</style>
