<template>
    <div class="text-red-500" v-if="hasError()">
        <ul class="list-reset">
            <li
                v-for="e in getError()"
                :key="e"
            >{{ e }}</li>
        </ul>
    </div>
</template>

<script>

    export default {

        name: "error",

        props: ['type', 'error'],

        methods: {
            hasError() {
                if (this.error == null || this.error.errors === undefined) {
                    return false;
                }

                return this.error.errors[this.type] !== undefined;
            },
            getError() {
                return this.error.errors[this.type];
            },
        },

    }

</script>
