<template>
    <span class="inline-flex" @click="$emit('click')">
        <button type="button"
                class="inline-flex items-center border border-transparent text-sm leading-5 font-medium rounded-md text-blue-900 focus:outline-none focus:border-blue-900 focus:shadow-outline-indigo transition ease-in-out duration-150">
            <slot>BUTTON TEXT</slot>
        </button>
    </span>
</template>

<script>
    export default {
        name: "ButtonLink"
    }
</script>

<style scoped>

</style>
