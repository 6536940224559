<template>
    <div>
        <label class="block text-sm font-medium leading-5 text-gray-700">
            <slot v-if="headline"></slot>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input v-bind:value="value"
                       :type="type"
                       v-on:input="$emit('input', $event.target.value)"
                       class="form-input block w-full sm:text-sm sm:leading-5"
                       :placeholder="defaultSlot"
                       :class="{'border-red-500 bg-red-100': hasError}"
                />
            </div>
        </label>
        <p class="mt-2 text-sm text-gray-500" v-if="hasHelperSlot">
            <slot name="helper"></slot>
        </p>
    </div>
</template>

<script>
export default {
    name: "InputText",

    props: {
        value: {},
        hasError: {},
        type: {
            default: 'text',
        },
        headline: {
            default: true,
        }
    },

    computed: {
        hasHelperSlot() {
            return !!this.$slots['helper'];
        },
        defaultSlot() {
            return this.$slots['default'][0].text.trim();
        }
    },


}
</script>

<style scoped>

</style>
